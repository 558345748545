<template>
  <div ref="questions-table" class="questions-table">
    <div class="mt-4 mb-3" v-if="!hideCheckbox">
      <el-checkbox
        v-model="showPassage"
        label="Passage"
        size="medium"
        border
      ></el-checkbox>
      <el-checkbox
        v-model="showQuestion"
        label="Entire Question"
        size="medium"
        border
      ></el-checkbox>
      <el-checkbox
        v-model="showOptions"
        label="Choices"
        size="medium"
        border
      ></el-checkbox>
      <el-checkbox
        v-model="showAnswer"
        label="Answer"
        size="medium"
        border
      ></el-checkbox>
      <el-checkbox
        v-model="showExplanation"
        label="Explanation"
        size="medium"
        border
      ></el-checkbox>
      <el-checkbox
        v-model="showLabels"
        label="Domain and Skills"
        size="medium"
        border
      ></el-checkbox>
    </div>
    <el-table :data="questions" v-if="questions && questions.length > 0">
      <el-table-column label="Question" min-width="600">
        <template slot-scope="scope">
          <QuestionDetail
            v-if="scope.row"
            :question="scope.row"
            :order="showOrder ? scope.$index + 1 : null"
            :search="search"
            :showQuestion="showQuestion"
            :showPassage="showPassage"
            :showOptions="showOptions"
            :showAnswer="showAnswer"
            :showExplanation="showExplanation"
            :showLabels="false"
          ></QuestionDetail>
        </template>
      </el-table-column>
      <el-table-column
        label="Domain and Skills"
        min-width="100"
        v-if="showLabels"
      >
        <template slot-scope="scope">
          <template
            v-if="
              scope.row.question_tags &&
                scope.row.question_tags.length > 0
            "
          >
            <div
              v-for="(tag_analysis, key, index) in questionTags(
                scope.row.question_tags
              )"
              :key="index"
              class="pb-2"
            >
              <el-tooltip
                v-for="tag in tag_analysis"
                :key="tag.id"
                class="item"
                effect="dark"
                :content="tag.name"
                placement="top"
              >
                <el-tag type="success" size="small" class="ivy-tag">
                  <b>{{ shortTag(tag.name) }}</b>
                </el-tag>
              </el-tooltip>
            </div>
          </template>
          <b v-else>
            -
          </b>
        </template>
      </el-table-column>
      <el-table-column label="Subject" min-width="80" v-if="subjects">
        <template slot-scope="scope">
          <div v-if="scope.row.sat_subject_id">
            <b> {{ subjects[scope.row.sat_subject_id].name }}</b>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Source" min-width="100">
        <template slot-scope="scope">
          <b v-if="scope.row.source === 1">
            SATMocks
          </b>
          <b v-else-if="scope.row.source === 2">
            Official
          </b>
          <b v-else-if="scope.row.source === 3">
            <span v-if="scope.row.company">
              {{ scope.row.company.name }}
            </span>
          </b>
        </template>
      </el-table-column>
      <el-table-column label="Difficulty" min-width="100">
        <template slot-scope="scope">
          <div>
            <b>
              {{
                scope.row.difficulty ? difficultyArr[scope.row.difficulty] : "-"
              }}
            </b>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Correct" min-width="80">
        <template slot-scope="scope">
          <div v-if="scope.row.answered_count > 0">
            <b
              >{{ getPer(scope.row.correct_count / scope.row.answered_count) }}%
            </b>
          </div>
          <div> - </div>
        </template>
      </el-table-column>
      <el-table-column label="Count" min-width="60" v-if="isAdmin">
        <template slot-scope="scope">
          <div class="text-success">
            <b> {{ scope.row.answered_count }} </b>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="# Tests" min-width="80">
        <template slot-scope="scope">
          <div v-if="scope.row.exam_questions">
            <el-popover placement="left" trigger="hover" width="450">
              <div>
                <!-- <router-link
                  v-for="tag in scope.row.exam_questions"
                  :key="tag.id"
                  :to="{
                    name: routerName(tag.exam.test_type),
                    query: { id: tag.exam.test_id }
                  }"
                  class="exam-tag"
                >
                  <el-tag
                    type="success"
                    size="small"
                    effect="dark"
                    class="ml-1 mr-1 mb-1"
                  >
                    {{ `${getCompanyExamTitle(tag.exam.title)}` }}
                  </el-tag>
                </router-link> -->
                <span
                  v-for="tag in scope.row.exam_questions"
                  :key="tag.id"
                  class="exam-tag"
                >
                  <el-tag
                    type="success"
                    size="small"
                    effect="dark"
                    class="ml-1 mr-1 mb-1"
                  >
                    <span v-if="tag.exam">
                      {{ `${getCompanyExamTitle(tag.exam.title)}` }}
                    </span>
                  </el-tag>
                </span>
              </div>
              <b
                class="text-success"
                slot="reference"
                style="text-decoration:underline;cursor: pointer;"
                >{{ scope.row.exam_questions.length }}</b
              >
            </el-popover>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Actions" width="200" fixed="right">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            content="Preview"
            placement="top"
          >
            <router-link
              :to="{
                name: 'EducatorQuestionDetail',
                params: {
                  questionId: scope.row.id
                }
              }"
            >
              <el-button type="success" size="mini">
                <i class="fas fa-eye"></i>
              </el-button>
            </router-link>
          </el-tooltip>
          <template v-if="isAdd">
            <template v-if="isEdit && !scope.row.isIn">
              <el-tooltip
                class="item"
                effect="dark"
                content="Add"
                placement="top"
              >
                <el-button
                  type="success"
                  size="mini"
                  @click="$emit('addQuestion', questions[scope.$index])"
                >
                  <i class="fas fa-plus-circle"></i>
                </el-button>
              </el-tooltip>
            </template>
            <el-button
              type="info"
              size="mini"
              :disabled="true"
              v-if="scope.row.isIn"
            >
              <span>Added</span>
            </el-button>
          </template>
          <template v-else>
            <template
              v-if="
                isAdmin ||
                  (scope.row.company &&
                    getProfile.company &&
                    scope.row.company.id === getProfile.company.id)
              "
            >
              <el-tooltip
                class="item"
                effect="dark"
                content="Duplicate"
                placement="top"
              >
                <router-link
                  :to="{
                    name: isAdmin
                      ? 'SatDuplicateQuestion'
                      : 'TeacherSatDuplicateQuestion',
                    query: {
                      questionId: scope.row.id
                    }
                  }"
                >
                  <el-button type="warning" size="mini">
                    <i class="fas fa-copy"></i>
                  </el-button>
                </router-link>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="Edit"
                placement="top"
              >
                <router-link
                  :to="{
                    name: isAdmin
                      ? 'SatEditQuestion'
                      : 'TeacherSatEditQuestion',
                    query: {
                      questionId: scope.row.id
                    }
                  }"
                >
                  <el-button type="warning" size="mini">
                    <i class="fas fa-edit"></i>
                  </el-button>
                </router-link>
              </el-tooltip>
            </template>
          </template>
        </template>
      </el-table-column>
      <!-- <el-table-column label="Preview" width="100" fixed="right" v-else>
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            content="Preview"
            placement="top"
          >
            <router-link
              :to="{
                name: 'QuestionPreview',
                params: {
                  questionId: scope.row.id
                }
              }"
            >
              <el-button type="success" size="mini">
                <i class="fas fa-eye"></i>
              </el-button>
            </router-link>
          </el-tooltip>
        </template>
      </el-table-column> -->
    </el-table>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user } from "@ivy-way/material";
import moment from "moment";
import _ from "lodash";
import SAT from "@/apis/sat.js";
import Common from "@/mixins/common.js";
import QuestionDetail from "@/views/questions/list/components/QuestionDetail";

export default {
  components: { QuestionDetail },
  mixins: [Common],
  props: [
    "questions",
    "order",
    "isAdd",
    "isEdit",
    "search",
    "canRemove",
    "showPassage",
    "showQuestion",
    "showOptions",
    "showAnswer",
    "showExplanation",
    "showLabels",
    "isAdmin",
    "showOrder",
    "hideCheckbox"
  ],
  data() {
    return {
      type: "passage",
      myPassage: 1,
      difficultyArr: {
        "1": "Beginner",
        "2": "Easy",
        "3": "Medium",
        "4": "Hard",
        "5": "Super Advanced"
      },
      subjects: null
    };
  },
  computed: {
    ...mapGetters("user", ["getProfile"]),
    userMethods() {
      return user;
    }
  },
  watch: {
    passage: {
      handler: function(newValue, oldValue) {
        // let type = "passage";
        this.myPassage = this.passage;
        if (this.passage === 0) {
          this.type = "single";
          this.myPassage = 1;
        } else {
          this.type = "passage";
          this.myPassage = this.passage;
        }
      },
      immediate: true
    },
    showPassage() {
      this.initMathJaxPlugin();
    },
    showExplanation() {
      this.initMathJaxPlugin();
    },
    showAnswer() {
      if (this.showAnswer) {
        this.showOptions = true;
      }
    },
    showOptions() {
      this.initMathJaxPlugin();
    },
    showQuestion() {
      this.initMathJaxPlugin();
    },
    questions() {
      this.initMathJaxPlugin();
    }
  },
  mounted() {
    // this.getSubjects();
    this.initMathJaxPlugin();
  },
  methods: {
    shortTag(tag) {
      let matches = tag.match(/\b(\w)/g); // ['J','S','O','N']
      return matches.join(""); // JSON
    },
    initMathJaxPlugin() {
      this.$mathJax.loadMathJaxScript(() => {
        this.$mathJax.initMathJaxConfig();
        this.$mathJax.MathQueue([this.$refs["questions-table"]]);
      });
    },
    routerName(type) {
      if (type === "Modules\\Practice\\Entities\\ComposePractice") {
        return "SatComposePracticeDetailAll";
      }
      if (type === "Modules\\Practice\\Entities\\Practice") {
        return "SatPracticeDetailAll";
      } else {
        return "SatPracticeDetailAll";
      }
    },
    async getSubjects() {
      const res = await SAT.getSubjects();
      let subjects = {};
      res.sat_subjects.forEach(subject => {
        subjects[`${subject.id}`] = {
          name: this.titleCase(subject.name)
        };
      });
      this.subjects = subjects;
    },
    // correctRate(rate) {
    //   if (Number(rate) > -1) {
    //     return (Number(rate) * 100).toFixed(0) + "%";
    //   } else {
    //     return "-";
    //   }
    // },
    getPer(val) {
      let str = 0;
      if (val > 0) {
        str = Math.round(val * 100);
      }
      return str;
    },
    questionTags(tags) {
      let questionTags = null;
      if (tags) {
        questionTags = _.groupBy(tags, "type");
      }
      return questionTags;
    },
    moment() {
      return moment();
    },
    changeColor(result) {
      if (this.search) {
        let replaceReg = new RegExp(this.search, "ig");
        let replaceString = `<span style="background-color: var(--themeColor);color:white;padding:0 0.1rem">${this.search}</span>`;
        result = result.replace(replaceReg, replaceString);
      }
      return result;
    }
  }
};
</script>

<style scoped>
::v-deep .question-content-title img,
::v-deep .show-passage img {
  display: none;
}
::v-deep .show-FullQuestion .question-content-title img {
  display: block;
  max-width: 400px;
}
::v-deep .el-popper {
  width: 450px !important;
}
.exam-tag:hover {
  opacity: 0.8;
}
.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
@font-face {
  font-family: DroidSerif;
  src: url("https://ivy-way.s3.ap-northeast-1.amazonaws.com/fonts/DroidSerif.ttf");
}
.ivy-question {
  font-family: DroidSerif;
}
::v-deep .el-button {
  margin: 0 0.3rem;
}
.passage-title {
  position: absolute;
  border: 1px solid #fefefe;
  background: var(--themeColor);
  /* font-size: 16px; */
  color: white;
  left: -2px;
  top: -15px;
  height: 34px;
  padding: 0 17px;
  line-height: 32px;
  border-radius: 5px 5px 0 0;
}
.question-content {
  position: absolute;
  right: -5px;
  top: -25px;
}
.question-content-title {
  /* font-size: 1.4rem; */
}
/* ::v-deep .question-content-title img {
  display: block;
  max-width: 60%;
  max-height: 250px;
  margin: 10px auto 20px;
} */
::v-deep .question-content-title p {
  margin: 0;
}
.remove-question {
  cursor: pointer;
  position: absolute;
  /* font-size: 18px; */
  right: -10px;
  top: -10px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
}
.tpos {
  display: block;
  margin: 10px 0;
  padding: 10px 20px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: #edf1f8;
  border-bottom: 2px solid #dadee5;
  color: #808080;
  transition: 0.2s 0s;
  border-radius: 5px;
}
.tpos:hover {
  box-shadow: 3px 5px 10px #aaa;
  color: var(--themeColor);
}
</style>
<style scoped>
.question-order {
  margin: 10px 0 10px 0;
  background-color: #ccc;
}
kbd {
  padding-right: 15px;
  padding-left: 15px;
}
.answer_content {
  justify-content: space-around;
}
.cover {
  height: calc(100vh - 200px);
}
.question-order {
  margin: 20px 0;
  background-color: #ccc;
}
.ivy-question {
  position: relative;
  overflow: visible;
  background-color: #f8f8f8;
}
.add-button {
  position: absolute;
  top: 50%;
}
.ivy-question .el-card {
  overflow: visible;
}
.ivy-question-options {
  /* font-size: 16px; */
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none;
}
.ivy-question-options .option {
  display: flex;
  /* font-size: 16px; */
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  padding: 0;
}
::v-deep .ivy-question-options .option p {
  margin-bottom: 0 !important;
}
.ivy-tag {
  margin: 5px;
}
.hidden {
  display: none;
}
::v-deep .el-card__body {
  padding: 15px;
}
::v-deep .MathJax .mrow {
  /* font-size: 1rem !important; */
}
::v-deep .highlight {
  background-color: var(--themeColor);
  color: white;
  padding: 0 0.1rem;
}
.is-warning {
  background-color: #c0c4cc;
}
</style>
